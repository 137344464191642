import React, { useState, useEffect } from 'react'
import {
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles,
  Grid,
  Box,
} from '@material-ui/core'
import MonthNavigate from '../monthNavigate'
import { withTranslation } from 'react-i18next'
import ProgressBar from '../progressBar'
import { COLORS, ENDPOINTS, FONTS } from '../../constants'
import * as moment from 'moment'
import CHRSpinner from '../spinner'
import { styles } from './styles'
import axios from '../../axios'
import { Link } from 'gatsby'
import { useAppState } from '../../appState'
import CHROutlinedButton from '../outlinedButton'
// import Card from '../trendingCard'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
// import CHRdayPicker from './dayPicker'
// import CHRmonthPicker from './monthPicker'
// import CHRyearPicker from './yearPicker'
import getDaysInMonth from 'date-fns/getDaysInMonth'
import CollapsibleTableRow from '../collapsibleTableRow'
import bird from '../../assets/images/chirpyest-logo.svg'

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: COLORS.whiteStain,
    },
    '& td': {
      maxWidth: 250,
      fontFamily: FONTS.Graphik.GraphikLight,
    },
  },
}))(TableRow)

const ProTab = ({ t }) => {
  const classes = styles()

  const [appState] = useAppState()
  const [isLoading, setIsLoading] = useState({
    sharedLinks: true,
    topPerformingItems: true,
    userRecruits: true,
  })
  const [shoppingResult, setShoppingResult] = useState({})
  const [sharedLinks, setSharedLinks] = useState({
    data: [],
    finishedLoading: false,
    offset: 0,
    error: '',
  })
  const [performingItems, setPerformingItems] = useState({
    data: [],
    finishedLoading: false,
    offset: 0,
    size: 4,
    month: 0,
  })
  const [userRecruits, setUserRecruits] = useState({
    data: [],
    finishedLoading: false,
    offset: 0,
    size: 6,
    error: '',
  })
  const [earningsRange, setEarningRange] = useState({
    fromMonth: null,
    fromDay: null,
    fromYear: null,
    toMonth: null,
    toDay: null,
    toYear: null,
  })
  const [earningsResult, setEarningsResult] = useState({
    recruits: null,
    pending: null,
    paid: null,
    error: '',
  })
  const [monthlyTarget, setMonthlyTarget] = useState(null)
  const [monthlyTargetError, setMonthlyTargetError] = useState('')
  const [dateRangeError, setDateRangeError] = useState(false)
  const currentDateObj = new Date()
  const initialMonth = +currentDateObj.getMonth()
  const initialYear = +currentDateObj.getFullYear()
  const [shoppingDetailsQuery, setShoppingDetailsQuery] = useState({
    currentYear: initialYear,
    currentMonth: initialMonth,
  })
  const [monthlyTargetQuery, setMonthlyTargetQuery] = useState({
    currentYear: initialYear,
    currentMonth: initialMonth,
  })
  const [topPerformingQuery, setTopPerformingQuery] = useState({
    currentYear: initialYear,
    currentMonth: initialMonth,
  })
  const theme = useTheme()
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true })
  const isMediumSize = useMediaQuery(theme.breakpoints.down('md'), {
    noSsr: true,
  })

  useEffect(() => {
    getSharedLinks()
    getUserRecruits()
    const today = new Date()
    const month = today.getMonth() + 1
    const year = today.getFullYear()
    setEarningRange({
      fromMonth: 1,
      fromDay: 1,
      fromYear: year,
      toMonth: month,
      toDay: getDaysInMonth(new Date(year, month - 1)),
      toYear: year,
    })
  }, [])

  useEffect(() => {
    getShoppingDetalis()
  }, [shoppingDetailsQuery])

  useEffect(() => {
    getMonthlyTarget()
  }, [monthlyTargetQuery])

  useEffect(() => {
    getPerformingItems()
  }, [topPerformingQuery])

  useEffect(() => {
    const { fromDate, toDate, isRangeCorrect } = formatDate()
    if (isRangeCorrect) {
      getEarnings(fromDate, toDate)
      setDateRangeError(false)
    } else {
      setDateRangeError(true)
    }
  }, [earningsRange])

  const getShoppingDetalis = async () => {
    try {
      const url = `/api/v1/users/${appState.userId}/performance`
      const response = await axios.get(url, {
        params: {
          year: shoppingDetailsQuery.currentYear,
          month: shoppingDetailsQuery.currentMonth + 1,
        },
      })

      const { data } = response.data
      setShoppingResult({ ...data })
    } catch (err) {
      setShoppingResult({ error: t('messages.somethingWentWrong') })
    }
  }

  const getMonthlyTarget = async () => {
    try {
      const url = `/api/v1/users/${appState.userId}/performance`
      const response = await axios.get(url, {
        params: {
          year: monthlyTargetQuery.currentYear,
          month: monthlyTargetQuery.currentMonth + 1,
        },
      })

      const { data } = response.data
      const result = await {
        completed: data.purchases,
        base: appState.settings.chirpyestProTarget,
      }
      setMonthlyTarget({ ...result })
    } catch (err) {
      setMonthlyTargetError(t('messages.somethingWentWrong'))
    }
  }

  const getEarnings = async (fromDate, toDate) => {
    try {
      const {
        data: {
          data: { recruits, pending, paid },
        },
      } = await axios.get(
        `${ENDPOINTS.yourRecruits}?fromDate=${moment(fromDate).format(
          'MM/DD/YYYY'
        )}&toDate=${moment(toDate).format('MM/DD/YYYY')}`
      )

      setEarningsResult({
        recruits,
        pending: pending.toFixed(2),
        paid: paid.toFixed(2),
        error: '',
      })
    } catch (err) {
      setEarningsResult({
        error: t('messages.somethingWentWrong'),
      })
    }
  }

  const getSharedLinks = async () => {
    try {
      const url = `/api/v1/users/${appState.userId}/links?offset=${sharedLinks.offset}`
      const response = await axios.get(url)
      const data = response.data.data.links
      const result = sharedLinks.data.concat(data)

      setSharedLinks({
        finishedLoading: data.length === 0 || data.length < sharedLinks.size,
        offset: result.length,
        data: result,
      })
      setIsLoading(prevState => ({ ...prevState, sharedLinks: false }))
    } catch (err) {
      setIsLoading(prevState => ({
        ...prevState,
        sharedLinks: false,
      }))
      setSharedLinks({
        error: t('messages.somethingWentWrong'),
      })
    }
  }

  // TODO: this function is not currently used, handle error if used in the future
  const getPerformingItems = async () => {
    try {
      const url = `/api/v1/users/${appState.userId}/top-items`
      const response = await axios.get(url, {
        params: {
          year: topPerformingQuery.currentYear,
          month: topPerformingQuery.currentMonth + 1,
          offset:
            performingItems.month === topPerformingQuery.currentMonth + 1
              ? performingItems.size
              : 0,
          size:
            performingItems.month === topPerformingQuery.currentMonth + 1
              ? performingItems.size + 4
              : 4,
        },
      })

      const { data } = response.data
      const items: any = []
      data.forEach(element => {
        items.push({
          product_id: element.data.brandId,
          thumbnail_url: element.data.imageUrl,
          cashBack: element.cashback,
          name: element.data.name,
          brand_name: element.data.brandName,
          brand_id: element.data.brandId,
          network_id: element.data.networkId,
          price: Number(element.data.price.substring(1)),
          original_price: Number(element.data.price.substring(1)),
          tracking_url: element.data.tracking_url,
          product_url: element.data.link,
          clicksCount: element.clicks_count,
          sold: element.sold,
        })
      })
      let dataToShow = items.slice(performingItems.offset, performingItems.size)
      setPerformingItems({
        finishedLoading:
          data.length === 0 || data.length < performingItems.size,
        offset:
          performingItems.month === topPerformingQuery.currentMonth + 1
            ? performingItems.size
            : 0,
        size:
          performingItems.month === topPerformingQuery.currentMonth + 1
            ? performingItems.size + 4
            : 4,
        data:
          performingItems.month === topPerformingQuery.currentMonth + 1
            ? performingItems.data.concat(dataToShow)
            : dataToShow,
        month: topPerformingQuery.currentMonth + 1,
      })

      setIsLoading(prevState => ({ ...prevState, topPerformingItems: false }))
    } catch (err) {
      setIsLoading(prevState => ({ ...prevState, topPerformingItems: false }))
    }
  }

  const getUserRecruits = async () => {
    try {
      const response = await axios.get(ENDPOINTS.getUserRecruits)
      const data = response.data.data
      let dataToShow = data.slice(userRecruits.offset, userRecruits.size)

      setUserRecruits({
        finishedLoading: data.length === 0 || data.length < userRecruits.size,
        offset: userRecruits.size,
        size: 6 + userRecruits.size,
        data: userRecruits.data.concat(dataToShow),
      })
      setIsLoading(prevState => ({ ...prevState, userRecruits: false }))
    } catch (err) {
      setIsLoading(prevState => ({ ...prevState, userRecruits: false }))
      setUserRecruits({
        error: t('messages.somethingWentWrong'),
      })
    }
  }

  const formatDate = () => {
    const fromDate = moment(
      `${+earningsRange.fromMonth}-${earningsRange.fromDay}-${
        earningsRange.fromYear
      }`,
      'MM-DD-YYYY'
    )
    const toDate = moment(
      `${+earningsRange.toMonth}-${earningsRange.toDay}-${
        earningsRange.toYear
      }`,
      'MM-DD-YYYY'
    )
    const isRangeCorrect = toDate.diff(fromDate, 'days') > 0
    return {
      fromDate,
      toDate,
      isRangeCorrect,
    }
  }

  const dateRangeOnchange = (e, fieldName: string) => {
    setEarningRange({ ...earningsRange, [fieldName]: e })
  }

  return (
    <div>
      {/* your shopping + sharing - how you are doing */}
      <section className={classes.midWidth}>
        <Typography variant="h1">
          your shopping + sharing - how you are doing
        </Typography>
        {shoppingResult.error ? (
          <p className={classes.errorMsg}>{shoppingResult.error}</p>
        ) : (
          <>
            <MonthNavigate onMonthChange={setShoppingDetailsQuery} />
            <div className={classes.purchases}>
              <Typography variant="button">
                purchases: ${Number(shoppingResult.purchases).toFixed(2)}
              </Typography>
            </div>
            <div className={classes.cashBackPending}>
              <Typography variant="button">
                cash back pending: ${Number(shoppingResult.pending).toFixed(2)}
              </Typography>
            </div>
            <Box mt={2.5} />
            <Grid container alignItems="center">
              <div className={classes.cashBackPaid}></div>
              <Typography variant="button" className={classes.cashBackPaidText}>
                cash back paid: ${Number(shoppingResult.paid).toFixed(2)}
              </Typography>
            </Grid>
          </>
        )}
      </section>
      <section className={classes.midWidth}>
        <Typography variant="h1">monthly target</Typography>
        <Typography variant="subtitle1">
          to maintain your chirpyest pro status, you must shop or share $300
          dollars monthly. Since we have a waiting list for the pro program, if
          you do not make your monthly target for two months, your pro status
          will be paused.
        </Typography>
        {monthlyTargetError ? (
          <p className={classes.errorMsg}>{monthlyTargetError}</p>
        ) : (
          <>
            <MonthNavigate onMonthChange={setMonthlyTargetQuery} />
            <Box mt={5} />
            {monthlyTarget && (
              <ProgressBar
                completed={monthlyTarget.completed}
                base={monthlyTarget.base}
              />
            )}
          </>
        )}
      </section>
      <section className={classes.midWidth}>
        <Typography variant="h1">share with friends performance</Typography>
        {isLoading.sharedLinks ? (
          <CHRSpinner />
        ) : sharedLinks.error ? (
          <p className={classes.errorMsg}>{sharedLinks.error}</p>
        ) : (
          <>
            {!isMediumSize ? (
              <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableHeader}>
                        <Typography variant="subtitle1">item</Typography>
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        <Typography variant="subtitle1">link</Typography>
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        <Typography variant="subtitle1">created</Typography>
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        <Typography variant="subtitle1">
                          total clicks
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableHeader}>
                        <Typography variant="subtitle1">
                          total earned
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sharedLinks.data.map((row: any) => (
                      <StyledTableRow key={`${row.id}`}>
                        <TableCell>
                          <div className={classes.itemWrapper}>
                            <div className={classes.circleImgWrapper}>
                              <img
                                src={row.productImage ? row.productImage : bird}
                                className={classes.circleImg}
                              />
                            </div>
                            <div className={classes.productTitleWrapper}>
                              <p className={classes.productTitle}>
                                {row.productName ? row.productName : 'N/A'}
                              </p>
                              <p className={classes.brandName}>
                                {row.brandName}
                              </p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <a
                            href={`${row.shortUrl}`}
                            target="_blank"
                            style={{ color: COLORS.black }}
                          >
                            {`${row.shortUrl}`}
                          </a>
                        </TableCell>
                        <TableCell>
                          {moment(row.createdAt).format('MM/DD/YY')}
                        </TableCell>
                        <TableCell>{row.clicksCount}</TableCell>
                        <TableCell>
                          ${Number(row.earnings || 0).toFixed(2)}
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div>
                {sharedLinks.data.map((row: any) => (
                  <Grid container className={classes.tableCard}>
                    <Grid item sm={12} xs={12}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldName}
                      >
                        item
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sm={12}
                      xs={12}
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      <div className={classes.itemWrapper}>
                        <div className={classes.circleImgWrapper}>
                          <img
                            src={row.productImage ? row.productImage : bird}
                            className={classes.circleImg}
                          />
                        </div>
                        <div className={classes.productTitleWrapper}>
                          <p className={classes.productTitle}>
                            {row.productName ? row.productName : 'N/A'}
                          </p>
                          <p className={classes.brandName}>{row.brandName}</p>
                        </div>
                      </div>
                    </Grid>
                    <Grid item sm={4} xs={4}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldName}
                      >
                        link
                      </Typography>
                    </Grid>
                    <Grid item sm={8} xs={8}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldValue}
                      >
                        <a
                          href={`${row.shortUrl}`}
                          target="_blank"
                          style={{ color: COLORS.black }}
                        >
                          {`${row.shortUrl}`}
                        </a>
                      </Typography>
                    </Grid>
                    <Grid item sm={4} xs={4}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldName}
                      >
                        created
                      </Typography>
                    </Grid>
                    <Grid item sm={8} xs={8}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldValue}
                      >
                        {moment(row.createdAt).format('MM/DD/YY')}
                      </Typography>
                    </Grid>
                    <Grid item sm={4} xs={4}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldName}
                      >
                        total clicks
                      </Typography>
                    </Grid>
                    <Grid item sm={8} xs={8}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldValue}
                      >
                        {row.clicksCount}
                      </Typography>
                    </Grid>
                    <Grid item sm={4} xs={4}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldName}
                      >
                        total earned
                      </Typography>
                    </Grid>
                    <Grid item sm={8} xs={8}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldValue}
                      >
                        ${Number(row.earnings || 0).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </div>
            )}
            {!sharedLinks.finishedLoading && (
              <CHROutlinedButton
                label={t('dashboard.loadMore')}
                font={FONTS.Graphik.GraphikMedium}
                onClick={getSharedLinks}
              />
            )}
          </>
        )}
      </section>
      {/* <section className={classes.midWidth}>
        <Typography variant="h1">top performing items</Typography>
        <MonthNavigate onMonthChange={setTopPerformingQuery} />
        <Grid container spacing={3}>
          {isLoading.topPerformingItems ? (
            <CHRSpinner />
          ) : (
            [
              performingItems.data.length > 0 ? (
                performingItems.data.map(item => (
                  <Grid item lg={3}>
                    <Card
                      productInfo={item}
                      userId={appState.userId}
                      isTopItem={true}
                    />
                  </Grid>
                ))
              ) : (
                <section className={classes.midWidth}>
                  <Typography variant="subtitle1" align="center">
                    There are no items for this month!
                  </Typography>
                </section>
              ),
            ]
          )}
        </Grid>
        {!performingItems.finishedLoading && (
          <CHROutlinedButton
            label={t('dashboard.loadMore')}
            font={FONTS.Graphik.GraphikMedium}
            onClick={getPerformingItems}
          />
        )}
      </section> */}
      {/* signed up by you - your recruits */}
      <section className={classes.midWidth}>
        <Typography variant="h1">signed up by you - your recruits</Typography>
        {appState?.userProfile?.isReferralBonus && (
          <p className={classes.disclaimer}>
            * you will receive sign up bonus once recruit joins and makes first
            purchase using chirpyest
          </p>
        )}

        {isLoading.userRecruits ? (
          <CHRSpinner />
        ) : userRecruits.error ? (
          <p className={classes.errorMsg}>{userRecruits.error}</p>
        ) : (
          <>
            {!isPhone ? (
              <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ width: '25%' }}
                      >
                        <Typography variant="subtitle1">user</Typography>
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ width: '25%' }}
                      >
                        <Typography variant="subtitle1">joined</Typography>
                      </TableCell>
                      <TableCell
                        className={classes.tableHeader}
                        style={{ width: '25%' }}
                      >
                        <Typography variant="subtitle1">
                          used chirpyest
                        </Typography>
                      </TableCell>
                      {appState?.userProfile?.isReferralBonus && (
                        <TableCell
                          className={classes.tableHeader}
                          style={{ width: '25%' }}
                        >
                          <Typography variant="subtitle1">
                            referral bonus paid
                          </Typography>
                        </TableCell>
                      )}
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userRecruits.data.map((row: any, index: number) => (
                      <CollapsibleTableRow
                        key={`${row.id}`}
                        row={row}
                        customStyle={index % 2 ? classes.withBg : ''}
                      />
                    ))}
                  </TableBody>
                </Table>
              </div>
            ) : (
              <div>
                {userRecruits.data.map((row: any) => (
                  <Grid container className={classes.tableCard}>
                    <Grid item sm={6} xs={6}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldName}
                      >
                        user
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldValue}
                      >
                        <Link
                          to={`/s/${row.username}`}
                          style={{ color: COLORS.black }}
                          target="_blank"
                        >
                          {row.username || row.name}
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldName}
                      >
                        joined
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldValue}
                      >
                        {moment(row.created_at).format('MM/DD/YY')}
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldName}
                      >
                        used chirpyest
                      </Typography>
                    </Grid>
                    <Grid item sm={6} xs={6}>
                      <Typography
                        variant="subtitle1"
                        className={classes.fieldValue}
                      >
                        {row.used_chirpyest}
                      </Typography>
                    </Grid>
                    {appState?.userProfile?.isReferralBonus && (
                      <>
                        <Grid item sm={6} xs={6}>
                          <Typography
                            variant="subtitle1"
                            className={classes.fieldName}
                          >
                            referral bonus paid
                          </Typography>
                        </Grid>
                        <Grid item sm={6} xs={6}>
                          <Typography
                            variant="subtitle1"
                            className={classes.fieldValue}
                          >
                            {row.paid_bonus}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                ))}
              </div>
            )}
            {!userRecruits.finishedLoading && (
              <CHROutlinedButton
                label={t('dashboard.loadMore')}
                font={FONTS.Graphik.GraphikMedium}
                onClick={getUserRecruits}
              />
            )}
          </>
        )}
      </section>
      {/* earnings from your recruits */}
      {/* <section className={classes.midWidth}>
        <Typography variant="h1">earnings from your recruits</Typography>
        <Typography variant="subtitle1">Select a date range</Typography>
        <div className={classes.dateGroups}>
          <div className={classes.dateGroup}>
            <div className={classes.pickerWrapper}>
              {earningsRange.fromMonth && (
                <CHRmonthPicker
                  selectedMonth={earningsRange.fromMonth}
                  selectedYear={earningsRange.fromYear}
                  onFilterChange={(month: number) =>
                    dateRangeOnchange(month, 'fromMonth')
                  }
                />
              )}
            </div>
            <div className={classes.pickerWrapper}>
              {earningsRange.fromDay && (
                <CHRdayPicker
                  selectedDay={earningsRange.fromDay}
                  selectedMonth={earningsRange.fromMonth}
                  selectedYear={earningsRange.fromYear}
                  onFilterChange={(day: number) =>
                    dateRangeOnchange(day, 'fromDay')
                  }
                />
              )}
            </div>
            <div className={classes.pickerWrapper}>
              <CHRyearPicker
                start={2021}
                onFilterChange={(year: number) =>
                  dateRangeOnchange(year, 'fromYear')
                }
              />
            </div>
          </div>
          <Typography variant="subtitle1" className={classes.betweenDateGroups}>
            to
          </Typography>
          <div className={classes.dateGroup}>
            <div className={classes.pickerWrapper}>
              {earningsRange.toMonth && (
                <CHRmonthPicker
                  selectedMonth={earningsRange.toMonth}
                  selectedYear={earningsRange.toYear}
                  onFilterChange={(month: number) =>
                    dateRangeOnchange(month, 'toMonth')
                  }
                />
              )}
            </div>
            <div className={classes.pickerWrapper}>
              {earningsRange.toDay && (
                <CHRdayPicker
                  selectedDay={earningsRange.toDay}
                  selectedMonth={earningsRange.toMonth}
                  selectedYear={earningsRange.toYear}
                  onFilterChange={(day: number) =>
                    dateRangeOnchange(day, 'toDay')
                  }
                />
              )}
            </div>
            <div className={classes.pickerWrapper}>
              <CHRyearPicker
                start={2021}
                onFilterChange={(year: number) =>
                  dateRangeOnchange(year, 'toYear')
                }
              />
            </div>
          </div>
        </div>
        {earningsResult.error ? (
          <p className={classes.errorMsg}>{earningsResult.error}</p>
        ) : !dateRangeError ? (
          <div>
            <Typography variant="subtitle2">
              {t('chirpyestPro.recruitsUsed', {
                recruitsUsed: earningsResult.recruits,
              })}
            </Typography>
            <Typography variant="subtitle2">
              {t('chirpyestPro.earningsResult', {
                pending: earningsResult.pending,
              })}
            </Typography>
            <Typography variant="subtitle2">
              {t('chirpyestPro.earningsResultPaid', {
                paid: earningsResult.paid,
              })}
            </Typography>
          </div>
        ) : (
          <Typography variant="subtitle1">
            please set a correct range
          </Typography>
        )}
      </section> */}
    </div>
  )
}

export default withTranslation()(ProTab)
