import React from 'react'
import { Grid } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { styles } from './styles'
import { ROUTES } from '../../../constants'
import { useAppState } from '../../../appState'
import UseBrowserExtension from '../../../assets/images/use-browser-extension.png'
import CurateProducts from '../../../assets/images/curate-products.png'
import ShopEarnCashback from '../../../assets/images/shop-earn-cash-back.png'
import BlogItem from '../blogItem'
import CreateLinksEarn from '../../../assets/images/create-share-links-mobile.png'
import CreateLinksEarnTablet from '../../../assets/images/create-sharelinks-tablet.png'
import CurateProductsMobile from '../../../assets/images/curate-products-mobile.png'
import CurateProductsTablet from '../../../assets/images/curate-products-tablet.png'
import ShopEarnCashbackMobile from '../../../assets/images/shop-and-earn-cashback-mobile.png'
import ShopEarnCashbackTablet from '../../../assets/images/shop-earn-cashback-tablet.png'
import AddExtensionImg from '../../../assets/images/add-extension.png'
import { Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import CustomLink from '../../customLink'
import arrow from '../../../assets/images/arrow-right-light.svg'
interface TopSectionProps {
  t: TFunction
  isExtentionDownloaded: boolean
  addExtention: any
}

const AccordionLinks = ({
  t,
  isExtentionDownloaded,
  addExtention,
}: TopSectionProps) => {
  const classes = styles()
  const [appState] = useAppState()

  const deviceType = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  return (
    <div>
      {appState.username ? (
        <div className={classes.topSection}>
          <Grid container justifyContent="space-between" spacing={3}>
            <Grid
              item
              lg={4}
              md={4}
              sm={12}
              xs={12}
              className={classes.blogItemContainer}
            >
              {deviceType() === 'mobile' && (
                <BlogItem
                  blogImage={CreateLinksEarn}
                  blogTitle={`convert product links from your favorite retailer into a link you can share and earn.`}
                  link={ROUTES.createShareLink}
                  isBlog={'categoriesSection'}
                />
              )}
              {deviceType() === 'tablet' && (
                <BlogItem
                  blogImage={ShopEarnCashbackTablet}
                  blogTitle={''}
                  link={'ShopEarnCashback'}
                  isBlog={'topSection'}
                />
              )}
              {deviceType() === 'desktop' && (
                <BlogItem
                  blogImage={ShopEarnCashback}
                  blogTitle={''}
                  link={'ShopEarnCashback'}
                  isBlog={'topSection'}
                />
              )}
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={classes.blogItemContainer}
            >
              {deviceType() === 'mobile' && (
                <BlogItem
                  blogImage={ShopEarnCashbackMobile}
                  blogTitle={''}
                  link={'ShopEarnCashback'}
                  isBlog={'topSection'}
                />
              )}
              {deviceType() === 'tablet' && (
                <BlogItem
                  blogImage={CurateProductsTablet}
                  blogTitle={''}
                  link={'CurateProducts'}
                  isBlog={'topSection'}
                  user={appState}
                  linkUser={
                    appState.username
                      ? ROUTES.mainBoard.replace(':userName', appState.username)
                      : ROUTES.createShareLink
                  }
                />
              )}
              {deviceType() === 'desktop' && (
                <BlogItem
                  blogImage={CurateProducts}
                  blogTitle={''}
                  link={'CurateProducts'}
                  isBlog={'topSection'}
                  user={appState}
                  linkUser={
                    appState.username
                      ? ROUTES.mainBoard.replace(':userName', appState.username)
                      : ROUTES.createShareLink
                  }
                />
              )}
            </Grid>
            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={12}
              className={classes.blogItemContainer}
            >
              {deviceType() === 'mobile' && (
                <BlogItem
                  blogImage={CurateProductsMobile}
                  blogTitle={''}
                  link={'CurateProducts'}
                  isBlog={'topSection'}
                  linkUser={
                    appState.username
                      ? ROUTES.mainBoard.replace(':userName', appState.username)
                      : ROUTES.createShareLink
                  }
                />
              )}
              {deviceType() === 'tablet' && (
                <BlogItem
                  blogImage={CreateLinksEarnTablet}
                  blogTitle={`convert product links from your favorite retailer into a link you can share and earn.`}
                  link={ROUTES.createShareLink}
                  isBlog={'categoriesSection'}
                />
              )}
              {deviceType() === 'desktop' && (
                <BlogItem
                  blogImage={UseBrowserExtension}
                  blogTitle={''}
                  link={'UseBrowserExtension'}
                  isBlog={'topSection'}
                  add={addExtention}
                  isExtension={isExtentionDownloaded}
                />
              )}
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={classes.topSectionOld}>
          <div className={classes.topSectionLinks}>
            <div className={classes.customLinks}>
              <CustomLink linkText={t('shared.shop')}>
                <Typography variant="subtitle1" component="p">
                  <Link to={'/shop'} className={classes.accordionLink}>
                    {t('home.shopHere')}
                  </Link>{' '}
                  {t('home.or')}{' '}
                  <div
                    onClick={addExtention}
                    className={`${classes.accordionLink} ${classes.addExt}`}
                  >
                    {t('home.addOurExtension')}
                  </div>{' '}
                  {t('home.accordionLink1')}
                </Typography>
              </CustomLink>
              <CustomLink linkText={t('shared.share')}>
                <Typography variant="subtitle1" component="p">
                  {t('home.accordionLink2')}{' '}
                  <Link
                    to={
                      appState.username
                        ? ROUTES.mainBoard.replace(
                            ':userName',
                            appState.username
                          )
                        : ROUTES.signIn
                    }
                    className={classes.accordionLink}
                  >
                    {t('home.shoppingBoard')}
                  </Link>{' '}
                  {t('home.orOur')}{' '}
                  <Link
                    to={ROUTES.createShareLink}
                    className={classes.accordionLink}
                  >
                    {t('home.createShareLink')}
                  </Link>
                </Typography>
              </CustomLink>
              <CustomLink linkText={t('home.earnCashBack')}>
                <Typography variant="subtitle1" component="p">
                  {t('home.accordionLink3')}
                </Typography>
              </CustomLink>
            </div>
            {deviceType() === 'desktop' && (
              <img
                className={`${classes.circleImg} ${isExtentionDownloaded &&
                  classes.hideCircle}`}
                src={AddExtensionImg}
                alt="add-extension-img"
                onClick={addExtention}
              />
            )}

            {/* <div
              className={`${classes.circle} ${isExtentionDownloaded &&
                classes.hideCircle}`}
              onClick={addExtention}
            > */}

            {/* <div className={classes.circleInfo}>
                <Typography variant="h2" className={classes.circleText}>
                  {t('home.circleInfo')}
                </Typography>
                <img src={arrow} alt="arrow" />
              </div> */}
            {/* </div> */}
          </div>
          <div className={`${classes.topSectionLinks} ${classes.blogLinks}`}>
            <Link to={'/category/fashion'} className={classes.rowLink}>
              <span className={classes.rowLinkText}>{t('shared.fashion')}</span>
              {/* <Typography variant="h1" className={classes.rowLinkText}>
                {t('shared.fashion')}
              </Typography> */}
            </Link>
            <Link to={'/category/home'} className={classes.rowLink}>
              <span className={classes.rowLinkText}>{t('shared.home')}</span>
              {/* <Typography variant="h1" className={classes.rowLinkText}>
                {t('shared.home')}
              </Typography> */}
            </Link>
            <Link to={'/category/beauty'} className={classes.rowLink}>
              <span className={classes.rowLinkText}>{t('shared.beauty')}</span>
              {/* <Typography variant="h1" className={classes.rowLinkText}>
                {t('shared.beauty')}
              </Typography> */}
            </Link>
            <Link to={'/category/lifestyle'} className={classes.rowLink}>
              <span className={classes.rowLinkText}>
                {t('shared.lifestyle')}
              </span>
              {/* <Typography variant="h1" className={classes.rowLinkText}>
                {t('shared.lifestyle')}
              </Typography> */}
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default withTranslation()(AccordionLinks)
