import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS } from '../../constants'

export const styles = makeStyles(theme => ({
  wrapper: {
    '&:hover > $linkChildren': {
      visibility: 'visible',
      opacity: 1,
      height: '100%',
      minHeight: 28,
      maxHeight: 140,
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  linkText: {
    fontSize: '2.2rem',
    fontFamily: 'Schnyder XL Light',
    fontWeight: 300,
    marginBottom: 10,
    paddingLeft: 20,
    whiteSpace: 'nowrap',
    display: 'block',
    letterSpacing: '-0.01562em',
    lineHeight: '50px !important',
    [`@media (min-width: ${BREAKPOINTS.lg}px)`]: {
      fontSize: '3rem',
    },
    [`@media (min-width: ${BREAKPOINTS.xml}px)`]: {
      fontSize: '4rem',
      lineHeight: '86px !important',
    },
  },
  logo: {
    width: 50,
    height: 50,
    [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
      width: 45,
      height: 45,
    },
  },
  linkChildren: {
    paddingLeft: 70,
    opacity: 0,
    visibility: 'hidden',
    transition:
      'visibility 0.2s ease-out, opacity 0.2s ease-in, height 0.2s ease-out',
    height: 0,
  },
}))
