import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import { withTranslation } from 'react-i18next'
import chirpyestLogo from '../../assets/images/chirpyest-logo.svg'
import { styles } from './styles'

interface CustomLinkProps {
  children: React.ReactNode
  linkText: string
}

const CustomLink = ({ linkText, children }: CustomLinkProps) => {
  const classes = styles()

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <img
          src={chirpyestLogo}
          className={classes.logo}
          alt="custom-link-img"
        />
        <div>
          <span className={classes.linkText}>{linkText}</span>
          {/* <Typography variant="h1" className={classes.linkText}>
            {linkText}
          </Typography> */}
        </div>
      </div>
      <div className={classes.linkChildren}>{children}</div>
    </div>
  )
}

export default withTranslation()(CustomLink)
