import React, { useEffect, useReducer, useState } from 'react'
import { Router } from '@reach/router'
import PrivateRoute from '../components/privateRoute'
import Dashboard from '../components/dashboard'
import MemberHome from '../components/member'
import PersonalInfo from '../components/personalInfo'
import { ROUTES, ENDPOINTS } from '../constants'
import CreateShareLink from '../components/createShareLink'
import axios from '../axios'
import { useAppState } from '../appState'
import NotFoundPage from './404'
import Auth from '@aws-amplify/auth'

const actions = {
  LOAD_USER_PROFILE_REQUEST: 'LOAD_USER_PROFILE_REQUEST',
  LOAD_USER_PROFILE_FAILURE: 'LOAD_USER_PROFILE_FAILURE',
  LOAD_USER_PROFILE_SUCCESS: 'LOAD_USER_PROFILE_SUCCESS',
}

interface stateInterface {
  user: any
  isLoading: Boolean
  error: String
}

const initialState: stateInterface = {
  user: null,
  isLoading: false,
  error: null,
}

function reducer(state: any, action: { type: any; payload: any }) {
  switch (action.type) {
    case actions.LOAD_USER_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
        user: {
          ...action.payload,
          financialData: {
            isLoading: true,
            error: false,
          },
        },
        error: null,
      }
    case actions.LOAD_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: {
          ...action.payload,
          financialData: {
            ...action.payload.financialData,
            isLoading: false,
            error: false,
          },
        },
        error: null,
      }
    case actions.LOAD_USER_PROFILE_FAILURE:
      return {
        ...state,
        isLoading: false,
        user: {
          financialData: { isLoading: false, error: true },
        },
        error: action.payload,
      }
    default:
      throw new Error('Unknown action type')
  }
}

const App = () => {
  const [appState, dispatchAppAction] = useAppState()
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    let userId
    const getUserData = async () => {
      try {
        dispatch({ type: actions.LOAD_USER_PROFILE_REQUEST })
        const currentUserInfo = await Auth.currentCredentials()
        const currentSession = await Auth.currentSession()
        userId = await currentSession.getIdToken().payload['custom:user_id']
        const {
          data: { data },
        } = await axios.get(
          ENDPOINTS.user.replace(':id', appState.userId || userId)
        )

        const userProfile = {
          email: data.email,
          userName: data.username,
          fullName: data.name,
          imageKey: data.image,
          imageUrl: '' as Object | string,
          isPro: data.isPro,
          isCreator: data.isCreator,
          isReferralBonus: data.isReferralBonus,
          userDoublePayment: data.userDoublePayment,
          financialData: data.financialData || {
            pending: '0',
            lastClosedOut: '0',
            receivableMilestone: '0',
          },
          loginProvider: data.loginProvider,
        }
        if (data.image && currentUserInfo?.identityId) {
          const url = `https://${process.env.GATSBY_S3_BUCKET_NAME}.s3.us-east-2.amazonaws.com/public/${data.image}`
          userProfile.imageUrl = url
        }

        dispatchAppAction({ type: 'UPDATE_USER_DATA', userProfile })

        if (!data.financialData) {
          data.financialData = {
            pending: '0',
            lastClosedOut: '0',
            receivableMilestone: '0',
          }
        }
        dispatch({
          type: actions.LOAD_USER_PROFILE_SUCCESS,
          payload: data,
        })
      } catch (error) {
        dispatch({ type: actions.LOAD_USER_PROFILE_FAILURE, payload: error })
      }
    }

    getUserData()
  }, [appState.userId, dispatch])

  return (
    <Router basepath={ROUTES.user}>
      <PrivateRoute
        component={Dashboard}
        path={ROUTES.mainDashboard}
        {...state.user}
        {...appState.userProfile}
        userId={appState.userId}
      />
      <PrivateRoute component={MemberHome} path={ROUTES.mainMember} />
      <PrivateRoute
        component={PersonalInfo}
        path={ROUTES.mainPersonalInfo}
        {...state.user}
        {...appState.userProfile}
      />
      <PrivateRoute
        component={CreateShareLink}
        path={ROUTES.mainCreateShareLink}
      />
      <NotFoundPage default withLayout={false} />
    </Router>
  )
}

export default App
