import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { ROUTES } from './../../constants'
import { withTranslation } from 'react-i18next'
import { useAppState } from '../../appState'
import Auth from '@aws-amplify/auth'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [appState] = useAppState()
  const isLoggedIn = appState.userId > 0
  useEffect(() => {
    const checkAuth = async () => {
      try {
        let auth = await Auth.currentSession()
        const isAuthenticated = auth.isValid()
        if (!isAuthenticated) navigate(ROUTES.signIn)
      } catch (err) {
        navigate(ROUTES.signIn)
      }
    }
    checkAuth()
  }, [])

  return <div>{isLoggedIn && <Component {...rest} />}</div>
}
export default withTranslation()(PrivateRoute)
