import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { styles } from './styles'
import { ROUTES } from '../../../constants'
import { useAppState } from '../../../appState'
import BlogItem from '../blogItem'
import CreateLinksEarn from '../../../assets/images/sharelinks-and-earn.png'
import ChirpyestBoards from '../../../assets/images/chirpyest-boards.png'
import ChirpyestBoardsMobile from '../../../assets/images/the-chirpyest-mobile.png'
import ChirpyestBoardsTablet from '../../../assets/images/the-chirpyest-tablet.png'
import UseBrowserExtensionMobile from '../../../assets/images/use-browser-extension-mobile.png'
import UseBrowserExtensionTablet from '../../../assets/images/use-browser-extension-tablet.png'

interface categoriesSectionProps {
  t: TFunction
  isExtentionDownloaded: boolean
  addExtention: any
}

const AccordionLinks = ({
  t,
  isExtentionDownloaded,
  addExtention,
}: categoriesSectionProps) => {
  const classes = styles()
  const deviceType = () => {
    const ua = navigator.userAgent
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return 'tablet'
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  const [appState] = useAppState()
  return (
    <div className={classes.topSection}>
      <div className={`${classes.topSectionLinks} ${classes.blogLinks}`}>
        <span className={classes.rowText}>{t('shared.shop')}</span>
        {/* <Typography variant="h1" className={classes.rowText}>
          {t('shared.shop')}
        </Typography> */}
        <Link to={'/category/fashion'} className={classes.rowLink}>
          <span className={classes.rowLinkText}>{t('shared.fashion')}</span>
          {/* <Typography variant="h1" className={classes.rowLinkText}>
            {t('shared.fashion')}
          </Typography> */}
        </Link>
        <Link to={'/category/home'} className={classes.rowLink}>
          <span className={classes.rowLinkText}>{t('shared.home')}</span>
          {/* <Typography variant="h1" className={classes.rowLinkText}>
            {t('shared.home')}
          </Typography> */}
        </Link>
        <Link to={'/category/beauty'} className={classes.rowLink}>
          <span className={classes.rowLinkText}>{t('shared.beauty')}</span>
          {/* <Typography variant="h1" className={classes.rowLinkText}>
            {t('shared.beauty')}
          </Typography> */}
        </Link>
        <Link to={'/category/lifestyle'} className={classes.rowLink}>
          <span className={classes.rowLinkText}>{t('shared.lifestyle')}</span>
          {/* <Typography variant="h1" className={classes.rowLinkText}>
            {t('shared.lifestyle')}
          </Typography> */}
        </Link>
      </div>
      <div className={classes.categoriesMargin}>
        <Grid container justifyContent="space-between" spacing={3}>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={12}
            className={classes.blogItemContainer}
          >
            {deviceType() === 'mobile' && (
              <BlogItem
                blogImage={ChirpyestBoardsMobile}
                blogTitle={'browse other members’ boards for inspo'}
                link={ROUTES.theChirpyest}
                isBlog={'categoriesSection'}
              />
            )}
            {deviceType() === 'tablet' && (
              <BlogItem
                blogImage={ChirpyestBoardsTablet}
                blogTitle={''}
                link={'ShopEarnCashback'}
                isBlog={'topSection'}
              />
            )}
            {deviceType() === 'desktop' && (
              <BlogItem
                blogImage={ChirpyestBoards}
                blogTitle={'browse other members’ boards for inspo'}
                link={ROUTES.theChirpyest}
                isBlog={'categoriesSection'}
              />
            )}
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={12}
            className={classes.blogItemContainer}
          >
            {deviceType() === 'mobile' && (
              <BlogItem
                blogImage={UseBrowserExtensionMobile}
                blogTitle={''}
                link={'UseBrowserExtension'}
                isBlog={'topSection'}
                add={addExtention}
                isExtension={isExtentionDownloaded}
              />
            )}
            {deviceType() === 'tablet' && (
              <BlogItem
                blogImage={UseBrowserExtensionTablet}
                blogTitle={''}
                link={'UseBrowserExtension'}
                isBlog={'topSection'}
                add={addExtention}
                isExtension={isExtentionDownloaded}
              />
            )}
            {deviceType() === 'desktop' && (
              <BlogItem
                blogImage={CreateLinksEarn}
                blogTitle={`convert product links from your favorite retailer into a link you can share and earn.`}
                link={ROUTES.createShareLink}
                isBlog={'categoriesSection'}
              />
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default withTranslation()(AccordionLinks)
